import React from "react";
import { Typewriter } from "react-simple-typewriter";
import { motion } from "framer-motion";
import { Animated } from "react-animated-css";

import file from "../assets/images//customers.jpg";

function Landing() {
  return (
    <div className={` max-w-7xl mx-auto`}>
      <div className="p-5 ">
        <div className="flex flex-col justify-between items-center mt-5 md:mt-20 gap-10 ">
          <div className="mx-28 w-2/3 flex flex-col gap-5 items-center justify-center">
            <span className="px-5 py-2 bg-white text-neutral-800 font-bold rounded-3xl">
              Meet Truetab
            </span>
            <h1 className="text-4xl text-center md:text-7xl font-clash font-extrabold">
              The All-In-One Operating System for <br />
              <span className="text-nelsa_blue ">
                {/* Style will be inherited from the parent element */}
                <Typewriter
                  words={[
                    "Restaurants",
                    "Bars",
                    "Eatries",
                    "Cafes",
                    "Canteens",
                    "Bukas",
                    "Food trucks",
                  ]}
                  loop={0}
                  cursor
                  cursorStyle="_"
                  typeSpeed={80}
                  deleteSpeed={90}
                  delaySpeed={1000}
                />
              </span>
              <br />
            </h1>

            <p className=" text-zinc-200 text-center text-md  md:text-base mt-5">
              Running a restaurant is no easy feat, but it shouldn’t be a
              constant uphill battle. That’s where we come in. Truetab takes the
              hassle out of managing your day-to-day operations—helping you save
              time, cut costs, and unlock new revenue streams.
            </p>
          </div>

          {/* <motion.div
            className=""
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            <img
              className="rounded-lg cover"
              src={portrait}
              alt="landing.png"
            />
          </motion.div> */}
        </div>
      </div>
      <div className="mt-10">
        <h2 className="text-3xl md:text-5xl font-bold text-center">
          What Can Truetab Do for You?
        </h2>
      </div>
      <div className="flex flex-col md:flex-row justify-center w-full my-1 md:my-10 mb-5 md:mb-20 gap-5 md:gap-16 p-5">
        <div className="w-full md:w-1/3 bg-nelsa_blue rounded-lg p-5 md:p-10 flex items-center">
          <img
            className="scale-100 rounded-lg bg-[length:200px_100px]"
            src={file}
            alt=""
          />
        </div>

        <div className="w-full md:w-1/2 flex flex-col justify-between rounded-lg gap-5 md:gap-16">
          <div className="w-full rounded-md bg-white p-8">
            <div className="flex flex-row gap-3">
              <div className="">
                <h1 className="text-xl font-bold text-zinc-900">
                  Save Money, Boost Profits
                </h1>
                <p className="leading-snug text-sm text-neutral-600 mt-2">
                  <span className="font-semibold">No More Costly Fees:</span>
                  Tired of losing up to 30% of your revenue to third-party
                  delivery platforms? With Truetab, you get your own online
                  ordering system—commission-free. Keep more of what you earn.
                </p>
                <p className="leading-snug text-sm text-neutral-600 mt-2">
                  <span className="font-semibold">
                    Smart Inventory Management:
                  </span>
                  Automatic low stock alerts prevent waste and keep you in
                  control, so you’re never caught off guard.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full rounded-md bg-white p-8">
            <div className="flex flex-row gap-3">
              <div className="">
                <h1 className="text-2xl font-bold text-zinc-900 ">
                  Streamline Your Operations
                </h1>
                <p className="leading-snug text-sm text-neutral-600 mt-2">
                  <span className="font-semibold">
                    Seamless Order Management:
                  </span>{" "}
                  From online orders to table-side service, everything is
                  organized in one place. No more chaos, just smooth, efficient
                  workflows.
                </p>
                <p className="leading-snug text-sm text-neutral-600 mt-2">
                  <span className="font-semibold">
                    Real-Time Payment Tracking:
                  </span>{" "}
                  Stay on top of your cash flow with instant updates on every
                  transaction.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full rounded-md bg-white p-8">
            <div className="flex flex-row gap-3">
              <div className="">
                <h1 className="text-2xl font-bold text-zinc-900">
                  Connect Directly with Customers
                </h1>
                <p className="leading-snug text-sm text-neutral-600 mt-2">
                  <span className="font-semibold">
                    Your Own Branded Website:
                  </span>{" "}
                  Forget third party delivery apps. Let your customers order
                  directly from your restaurant with a sleek, easy-to-use
                  website.
                </p>
                <p className="leading-snug text-sm text-neutral-600 mt-2">
                  <span className="font-semibold">Customer Loyalty:</span> Build
                  lasting relationships and reward loyal customers with
                  personalized experiences.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mb-10 gap-7 p-5">
        <h3 className="text-3xl md:text-5xl font-bold">Why Truetab?</h3>
        <div className="flex flex-col md:flex-row gap-10">
          <div className="w-full md:w-1/3 flex flex-col bg-neutral-700 p-5 rounded-md">
            <h1 className="text-7xl font-extrabold">1</h1>
            <div>
              <h4 className="font-lg font-semibold">We’re Restaurant People</h4>
              <p className="text-sm text-neutral-400">
                Built by a team that knows the ins and outs of the industry,
                we’ve designed Truetab to meet the real needs of restaurant
                owners like you.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/3 flex flex-col bg-neutral-700 p-5 rounded-md">
            <h1 className="text-7xl font-extrabold">2</h1>
            <div>
              <h4 className="font-lg font-semibold">Scalable Solutions</h4>
              <p className="text-sm text-neutral-400">
                Whether you run a cozy café or a bustling chain, Truetab grows
                with your business, offering features tailored to your unique
                needs.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/3 flex flex-col bg-neutral-700 p-5 rounded-md">
            <h1 className="text-7xl font-extrabold">3</h1>
            <div>
              <h4 className="font-lg font-semibold">Unmatched Support</h4>
              <p className="text-sm text-neutral-400">
                We’re with you every step of the way, offering responsive
                support and expert guidance to help your business thrive.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#191716] flex flex-col p-7 rounded-lg gap-5 mb-20 m-5">
        <h4 className="text-md font-semibold text-neutral-500">
          Join the Truetab Family Today
        </h4>
        <div className="flex flex-col md:flex-row justify-between md:items-center gap-5">
          <div className="flex flex-col gap-2 w-full md:w-1/2">
            <h4 className="text-4xl font-bold">
              Ready to take your restaurant to the next level?
            </h4>
            <p className="text-sm text-neutral-300">
              Sign up to be the first to know when Truetab launches and enjoy
              exclusive early-bird benefits. Truetab is here to help you save
              money, operate smoothly, and grow your business like never before.
            </p>
          </div>
          <div>
            <motion.a
              href="https://forms.gle/MiZt5JGjoRpH8EEF8"
              target="_blank"
              class="bg-nelsa_blue text-white font-bold p-3 rounded-lg inline-flex items-center"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
            >
              <span className=" ml-2 text-lg tracking-wide flex flex-row">
                Get on the Waitlist
                <svg
                  className="w-4 ml-3"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.4297 5.93018L20.4997 12.0002L14.4297 18.0702"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.5 12H20.33"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </motion.a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landing;
